import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Container from 'layout/Container';

import Title from 'common/Title';
import BreadCrumbs from 'common/BreadCrumbs';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsLdsPage } from './models';
import './LdsPage.scss';

const LdsPage: FC<IPropsLdsPage> = ({ data: { pageData }, pageContext: { breadcrumbs } }) => {
  const { seo, defaultCompositions } = pageData;

  return (
    <Layout defaultCompositions={defaultCompositions} seo={seo}>
      <div data-test="LdsPage" className="lds-page">
        <Container fluid>
          <BreadCrumbs breadcrumbs={breadcrumbs} />

          <Title dataTestAttr="LdsPageTitle" Tag="h1" className="lds-page__title">
            {pageData.title}
          </Title>

          <DangerouslySetInnerHtml
            html={pageData?.ldsInfo?.[0]?.properties?.body}
            className="lds-page__content"
          />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!, $lang: String!) {
    pageData: lds(link: { eq: $link }, lang: { eq: $lang }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      ldsInfo {
        properties {
          body
        }
      }
    }
  }
`;

export default LdsPage;
